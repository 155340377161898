// Generated by Framer (532f6a7)

import { addFonts, cx, CycleVariantState, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["v7TRXyaO2"];

const variantClassNames = {v7TRXyaO2: "framer-v-m2jn74"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "v7TRXyaO2", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "v7TRXyaO2", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-FP0rK", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-m2jn74", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"v7TRXyaO2"} ref={ref} style={{...style}} transition={transition}><SVG className={"framer-ynoipl"} data-framer-name={"Group"} layout={"position"} layoutDependency={layoutDependency} layoutId={"NB02yngPb"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 15 15\"><path d=\"M 7.5 14.712 C 11.483 14.712 14.712 11.483 14.712 7.5 C 14.712 3.517 11.483 0.288 7.5 0.288 C 3.517 0.288 0.288 3.517 0.288 7.5 C 0.288 11.483 3.517 14.712 7.5 14.712 Z\" fill=\"#599582\"></path><path d=\"M 5.097 8.041 L 6.749 9.543 L 10.545 5.637\" fill=\"transparent\" stroke-width=\"1.1538461538461537\" stroke=\"rgb(255,255,255)\" stroke-linecap=\"square\" stroke-miterlimit=\"5.769230769230768\" stroke-dasharray=\"\"></path></svg>"} svgContentId={2587539309} transition={transition} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-FP0rK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FP0rK .framer-3f8a50 { display: block; }", ".framer-FP0rK .framer-m2jn74 { height: 15px; overflow: hidden; position: relative; width: 15px; }", ".framer-FP0rK .framer-ynoipl { flex: none; height: 15px; left: calc(46.66666666666669% - 15px / 2); position: absolute; top: calc(50.00000000000002% - 15px / 2); width: 15px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 15
 * @framerIntrinsicWidth 15
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerLlgCdtIHz: React.ComponentType<Props> = withCSS(Component, css, "framer-FP0rK") as typeof Component;
export default FramerLlgCdtIHz;

FramerLlgCdtIHz.displayName = "Green Check";

FramerLlgCdtIHz.defaultProps = {height: 15, width: 15};

addFonts(FramerLlgCdtIHz, [])